import "./polyfills";
import { contactSubmit } from './contact.js';
import { initMobileNav } from "./header";
import { initReviewsCaro } from './reviews';``;

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.querySelector("#reviews_caro")) {
	initReviewsCaro();
}

initMobileNav();

//Session Features logic
if (document.querySelector('.item_wrap')) {
	setTimeout(function(){
		document.getElementsByClassName('item_wrap')[0].classList.add('active');
		document.getElementsByClassName('item_text_wrap')[0].classList.add('active');
	}, 500);
	const wrapItems = document.querySelectorAll('.item_wrap');
	document.querySelectorAll('.item_wrap').forEach((item) => {
		item.addEventListener('click', function () {
			const textWrap = document.querySelector('.item_text_wrap');
			if (item.classList.contains('active')) {
				item.classList.remove('active');
				textWrap.classList.remove('active');
			} else {
				wrapItems.forEach(i => {
					i.classList.remove('active');
				});
				item.classList.add('active');
				textWrap.classList.add('active');
			}
		});
	});
}